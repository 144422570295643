

.app {
       /* fallback for old browsers */
    background: #fff5f5;
  
    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right, rgba(255,245,245,0.5), rgba(236,228,236,0.5));
  
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right, rgba(255,245,245,0.5), rgba(236,228,236,0.5))
      }

.button {
    background-color: mistyrose  !important;
}

.title{
    color: pink!important;
    font-weight: 500;   
    
}

.component-button-1{
    margin-left: 3.5vw;

}

.component-button-2{
    margin-left: 5.5vw;

}

.component-button-3{
    margin-left: 4.7vw;

}

        
        
.add-color {
    color: black !important;
    font-weight:600;
}

.description {
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}


.subtitle {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: bolder;

}


.header {
    display: block;
    text-align: center;
    font-size: 40px;
    font-weight: 800;
    margin-top: 0.5vw;
    color: white;
  }


  
  .cartitem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 30px;
    margin-bottom: 2.5vw;
  }
  
  .cartItemImg {
    border-radius: 60%;
    width: 55px;
    height: 52px;
    object-fit: cover;
  }
  
  .cartItemDetail {
    display: flex;
    flex: 1;
    padding: 0 20px;
    flex-direction: column;
  }
  
  /* Home Page */
  
  .home {
    display: flex;
  }
  
  .filters {
    background-color: black;
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 20%;
    margin: 4.5vh;
    height: 200vh;
  }
  
  .filters > span {
    padding-bottom: 15px;
  }
  
  .title {
    font-size: 30px;
  }
  
  .productContainer {
    display: flex;
    width: 78%;
    padding: 40px;
    flex-wrap: wrap;
    justify-content: space-around;
 
  }
  
  .products {
    width: 30%;
    margin-bottom: 2vw;
  }
  
  .summary {
    width: 30%;
  }
  
  /* Media Queries */
  @media (max-width: 771px) {
    .filters {
      width: 40%;
      padding: 6px;
      margin: 5px;
    }
  
    .filters > span {
      font-size: 20px;
    }
  
  
    .productContainer {
      width: 60%;
      padding: 0;
    }
  
    .search {
      display: none !important;
    }
  
    .products {
      width: 80%;
    }
  }

  .checkout-title {
    color:black;
  }